
import { reactive, onMounted } from 'vue'
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import axios from "@/plugin/vue-axios";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumTextBox from '@/components/ReumLib/UI/ReumTextBox.vue'


export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumTextBox
    },
    props: {
        id: {
            type: [Number, String],
            default: -1,
        },
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();

        const options = {
            status: [
                {value: "DNA Prep 완료", label:"DNA Prep 완료"},
                {value: "DNA 재추출", label:"DNA 재추출"},
                {value: " 검체 재요청", label:" 검체 재요청"},
            ],
            report_status : [
                {value: "검체 재요청", label:"검체 재요청"},
                {value: "Report generation", label:"Report generation"},
                {value: "Report complete", label:"Report complete"},
            ],
            service_status : [
                {value: "", label:"미입력"},
                {value: "S", label:"Service"},
                {value: "R", label:"Research"},
            ],
            status_qc: [
                {value: "", label:"미입력"},
                {value: "PASS", label:"PASS", color:"#00F"},
                {value: "FAIL", label:"FAIL", color:"#F00"},
            ],
            gender : [
                {value: "", label:"미입력"},
                {value: "M", label:"남성"},
                {value: "F", label:"여성"},
            ]
        }

        const comp = reactive({
            id:0,
            is_view: true,
            barcode: "",
            info: null,
            changeQC: () => {
                // if(comp.info.qc=="PASS") {
                //     options.status = [
                //         {value: "", label:"미지정"},
                //         {value: "DNA Prep 완료", label:"DNA Prep 완료"}
                //     ];
                // } else if(comp.info.qc=="FAIL") {
                //     options.status = [
                //         {value: "", label:"미지정"},
                //         {value: "DNA 재추출", label:"DNA 재추출"},
                //         {value: " 검체 재요청", label:" 검체 재요청"},
                //     ];
                // } else {
                //     options.status = [
                //         {value: "DNA Prep 완료", label:"DNA Prep 완료"},
                //         {value: "DNA 재추출", label:"DNA 재추출"},
                //         {value: " 검체 재요청", label:" 검체 재요청"},
                //     ];
                // }
            },
            modify: () => {
                comp.is_view = false;
            },
            delete: () => {
                let params = {
                    id: comp.info.id
                }
                axios.post("/api/DNAPrep/delete", params).then((res) => {
                    if(res.data.err == 0) {
                        router.push({path: "/DNAPrep/list"});
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            goView: () => {
                comp.is_view = true;
                comp.getInfo();
            },
            goList: () => {
                router.push({path: "/DNAPrep/list"});
            },
            getBarcodeInfo: () => {
                let params = {
                    barcode: comp.barcode,
                }
                axios.post("/api/common/barcode", params).then((res) => {
                    if(res.data.err == 0) {
                        console.info(comp.info);
                        comp.info.serial_no = res.data.info.serial_no;
                        comp.info.client = res.data.info.client;
                        comp.info.date_of_collection = res.data.info.date_of_collection;
                        comp.info.gender = res.data.info.gender;
                        comp.info.product = res.data.info.product;
                        comp.info.tat = res.data.info.tat;
                        comp.info.original_serial_no = res.data.info.original_serial_no;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            setInfo: () => {
                let params = {
                    id:comp.id,
                    ...comp.info
                }
                axios.post("/api/DNAPrep/upsert", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.goList();
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            getInfo: () => {
                let params = {
                    tmr: new Date(),
                }
                axios.get("/api/DNAPrep/info/"+ Math.round(comp.id), {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.info = res.data.info;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            if(route.params.id=="new") {
                comp.id = 0;
                comp.is_view = false;
                comp.info = {};
            } else {
                comp.id = route.params.id;
                if(comp.id==="") {
                    router.replace({"path":"/DNAPrep/list"});
                } else {
                    comp.getInfo();
                }
            }
        });
        return {
            comp,
            options
        };
    },
};
